<template>
    <div>
        <div class="data-form">
            <ValidationObserver ref="importForm">
                <div class="row">
                    <div class="col-12">
                        <ValidationProvider name="file" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('excel_file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file v-model="defineForm.file" :state="errors[0] ? false : null"
                                        :placeholder="$t('select_file')" :drop-placeholder="$t('drop_file')" ref="fileInput"
                                        multiple></b-form-file>
                                    <b-button variant="outline-secondary"
                                        @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}
                                    </b-button>
                                </div>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                        <processing-button :processing="processing" :label="$t('import')" variant="primary"
                            @click="sendForm" />
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import ProcessingButton from "@/components/elements/ProcessingButton";
import StaffService from "@/services/StaffService";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    components: {
        ProcessingButton,
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            defineForm: {
                file: null,
            },
            processing: false,
        }
    },
    methods: {
        async sendForm() {
            const isValid = await this.$refs.importForm.validate();
            if (isValid) {
                this.processing = true
                let formData = new FormData();
                formData.append('file', this.defineForm.file[0])

                StaffService.importExtraPayments(formData)
                    .then(response => {
                        if(response.data.data.success_count){
                            this.$toast.success(response.data.data.success_count+' kayıt başarılı');
                        }
                        if(response.data.data.errors.length){
                            this.$toast.error(response.data.data.errors.length+' kayıt hatalı');
                            let msg=[];
                            response.data.data.errors.forEach(item => {
                                msg.push(item.line+": "+item.message);
                            })
                            this.$swal.fire(msg.join("<br>"));
                        }
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.importForm)
                    })
                    .finally(() => {
                        this.processing = false
                    })
            }
        }
    }
}
</script>
